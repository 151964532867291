import {action} from 'mobx';
import {HandleOptionChangeFn} from './types';
import {getProductVariantBySelectionIds} from '@wix/wixstores-client-core/dist/es/src/productVariantCalculator/ProductVariantCalculator';
import {mapUserInputSelectionMapToChoices} from '../../mappers/mapUserInputSelectionMapToChoices';
import {publishCustomizeProductEvent} from '../product-service/publishCustomizeProductEvent';
import {SPECS} from '../../../../specs';

export const handleOptionChange: HandleOptionChangeFn = (rootStore) => {
  const isQuantityTouchupsEnabled = rootStore.controllerParams.flowAPI.experiments.enabled(
    SPECS.QUANTITY_WIDGET_TOUCHUPS,
  );

  return action(({data: {selectionId, optionId}}) => {
    rootStore.$state.selectionInput[optionId] = selectionId;
    rootStore.$state.selectedVariant = getProductVariantBySelectionIds({
      product: rootStore.$state.product,
      variantSelectionIds: rootStore.$state.selectionIds,
    });

    if (isQuantityTouchupsEnabled) {
      rootStore.$state.quantityInput = 1;
    }
    publishCustomizeProductEvent(rootStore);

    if (rootStore.wixCodeService.onChoiceSelectedCallback) {
      rootStore.wixCodeService.onChoiceSelectedCallback(mapUserInputSelectionMapToChoices(rootStore));
    }
  });
};
