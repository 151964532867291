import {ExperimentsBag} from '@wix/yoshi-flow-editor';

export const SPECS = {
  PRODUCT_PAGE_BLOCKS_MS4: 'specs.stores.ProductPageBlockMS4',
  PRODUCT_PAGE_BLOCKS_MS3: 'specs.stores.ProductPageBlockMS3',
  UseNewCheckoutInVisitorShippingAndDigitalFlow: 'specs.stores.UseNewCheckoutInVisitorShippingAndDigitalFlow',
  ShouldUseVariantLevelPreOrderAvailable: 'specs.stores.ShouldUseVariantLevelPreOrderAvailablePPOB',
  PRODUCT_PAGE_BREADCRUMBS_LOCALIZE_ALL_PRODUCTS_REFERRING_PAGE:
    'specs.stores.ProductPageBreadcrumbsLocalizeAllProductsReferringPage',
  WIDGET_VIEW_STATES_ON_CTA: 'specs.stores.PPOBWidgetViewStatesOnCTA',
  QUANTITY_WIDGET_TOUCHUPS: 'specs.stores.ProductPageBlocksQuantityWidgetTouchups',
  AddOptionsToCartWithKeyInsteadOfTranslation: 'specs.stores.AddOptionsToCartWithKeyInsteadOfTranslation',
  PRODUCT_PAGE_BLOCKS_SLOTS: 'specs.stores.ProductPageBlocksSlots',
  PRODUCT_PAGE_RICH_CONTENT_DESCRIPTION_BLOCKS: 'specs.stores.ProductPageBlocksRico',
  RenderOnDeviceViewChanged: 'specs.stores.ProductPageBlocksRenderOnDeviceViewChanged',
  PPOBShouldUseProductLevelPricePerUnit: 'specs.stores.PPOBShouldUseProductLevelPricePerUnit',
  NonControlledDropdown: 'specs.stores.ProductPageBlocksNonControlledDropdown',
  CtaSettingsMobile: 'specs.stores.ProductPageBlocksCtaSettingsMobile',
  CtaViewerMobile: 'specs.stores.ProductPageBlocksCtaViewerMobile',
  FixPricingMismatch: 'specs.stores.ProductPageBlocksFixPricingMismatch',
} as const;

const openedExperimentsForTests: string[] = [
  SPECS.PRODUCT_PAGE_BLOCKS_MS3,
  SPECS.ShouldUseVariantLevelPreOrderAvailable,
  SPECS.PRODUCT_PAGE_BREADCRUMBS_LOCALIZE_ALL_PRODUCTS_REFERRING_PAGE,
  SPECS.QUANTITY_WIDGET_TOUCHUPS,
  SPECS.PRODUCT_PAGE_BLOCKS_SLOTS,
  SPECS.AddOptionsToCartWithKeyInsteadOfTranslation,
  SPECS.PPOBShouldUseProductLevelPricePerUnit,
  SPECS.NonControlledDropdown,
  SPECS.CtaSettingsMobile,
  SPECS.CtaViewerMobile,
  SPECS.FixPricingMismatch,
];

const openedExperimentsForSled = [
  'specs.thunderbolt.new_responsive_layout_loggedIn',
  'specs.stores.UseSlugAsWarmupDataKey',
  SPECS.ShouldUseVariantLevelPreOrderAvailable,
  SPECS.PRODUCT_PAGE_BREADCRUMBS_LOCALIZE_ALL_PRODUCTS_REFERRING_PAGE,
  SPECS.QUANTITY_WIDGET_TOUCHUPS,
  SPECS.PRODUCT_PAGE_BLOCKS_SLOTS,
  SPECS.NonControlledDropdown,
  SPECS.CtaSettingsMobile,
  SPECS.CtaViewerMobile,
  SPECS.FixPricingMismatch,
];

export const getOpenedExperimentsForTests = (): ExperimentsBag => {
  return openedExperimentsForTests.reduce((prv: any, cur: any) => {
    prv[cur] = 'true';
    return prv;
  }, {});
};

export const getOpenedExperimentsForSled = () => {
  return openedExperimentsForSled.reduce((obj, ex) => {
    obj[ex] = true;
    return obj;
  }, {});
};

export const mapExperimentsToStrings = (experiments: ExperimentsBag) => {
  return Object.entries(experiments).reduce((acc, [key, value]) => {
    acc[key] = value.toString();
    return acc;
  }, {} as Record<string, string>);
};

export const mapExperimentsToBooleans = (experiments: ExperimentsBag) => {
  return Object.entries(experiments).reduce((acc, [key, value]) => {
    if (value === true || value === false) {
      acc[key] = value;
      return acc;
    } else if (value === 'true' || value === 'false') {
      acc[key] = value === 'true' ? true : false;
      return acc;
    }
    return acc;
  }, {} as Record<string, boolean>);
};
