import {getProductsPriceInfo} from '@wix/wixstores-client-core/dist/es/src/productVariantCalculator/ProductVariantCalculator';
import {
  formattedPrimaryPrice,
  formattedSecondaryPrice,
} from '@wix/wixstores-client-core/dist/es/src/productOptions/productUtils';
import {RootStore} from '../../../stores/RootStore';
import {SPECS} from '../../../../../specs';

const getSubscriptionPricing = (
  rootStore: RootStore,
): {
  formattedActualPrice: string;
  frequency?: string;
  interval?: number;
} => {
  const {formattedPrice, interval, frequency} = rootStore.$state.selectedSubscriptionPlan;
  return {
    formattedActualPrice: formattedPrice,
    interval,
    frequency,
  };
};

const getProductPricing = (
  rootStore: RootStore,
): {
  formattedActualPrice: string;
  formattedOriginalPrice?: string;
} => {
  const {hasDiscount, product, selectionIds, selectedVariant} = rootStore.$state;
  const productWithVariant = selectedVariant ? {...product, ...selectedVariant} : product;
  const priceInfo = getProductsPriceInfo({product, variantSelectionIds: selectionIds});
  const formattedActualPrice = hasDiscount ? formattedPrimaryPrice(productWithVariant) : priceInfo.formattedPrice;
  const formattedOriginalPrice = hasDiscount ? formattedSecondaryPrice(productWithVariant) : undefined;

  return {
    formattedActualPrice,
    formattedOriginalPrice,
  };
};

const getFormattedPricePerUnit = (rootStore: RootStore) => {
  const {selectedSubscriptionPlan, selectedVariant, product} = rootStore.$state;
  const useProductLevelPricePerUnit = rootStore.isExperimentEnabled(SPECS.PPOBShouldUseProductLevelPricePerUnit);
  if (useProductLevelPricePerUnit) {
    return product.formattedPricePerUnit;
  } else {
    const ref = selectedSubscriptionPlan || selectedVariant || product;
    return ref.formattedPricePerUnit;
  }
};

export const getPriceFields = (
  rootStore: RootStore,
): {
  formattedActualPrice: string;
  formattedOriginalPrice?: string;
  formattedPricePerUnit?: string;
  frequency?: string;
  discountTitle?: string;
  interval?: number;
} => {
  const {$state} = rootStore;
  const {selectedSubscriptionPlan} = $state;
  const pricing = selectedSubscriptionPlan ? getSubscriptionPricing(rootStore) : getProductPricing(rootStore);
  const formattedPricePerUnit = getFormattedPricePerUnit(rootStore);
  return {
    ...pricing,
    formattedPricePerUnit,
  };
};

export const getPriceFieldsOld = (
  rootStore: RootStore,
): {
  formattedActualPrice: string;
  formattedOriginalPrice?: string;
  formattedPricePerUnit?: string;
  frequency?: string;
  discountTitle?: string;
  interval?: number;
} => {
  const ShouldUseProductLevelPricePerUnit = rootStore.isExperimentEnabled(SPECS.PPOBShouldUseProductLevelPricePerUnit);
  const {hasDiscount, product, selectionIds, selectedSubscriptionPlan, selectedVariant} = rootStore.$state;
  const productWithVariant = selectedVariant ? {...product, ...selectedVariant} : product;
  const priceInfo = !selectedSubscriptionPlan
    ? getProductsPriceInfo({product, variantSelectionIds: selectionIds})
    : {
        ...selectedSubscriptionPlan,
        formattedComparePrice: undefined,
      };
  const formattedActualPriceWithAutomatedDiscounts = hasDiscount
    ? formattedPrimaryPrice(productWithVariant)
    : priceInfo.formattedPrice;

  const formattedOriginalPriceWithAutomatedDiscounts = hasDiscount
    ? formattedSecondaryPrice(productWithVariant)
    : undefined;

  return {
    formattedActualPrice: formattedActualPriceWithAutomatedDiscounts,
    formattedOriginalPrice: formattedOriginalPriceWithAutomatedDiscounts,
    formattedPricePerUnit: ShouldUseProductLevelPricePerUnit
      ? product.formattedPricePerUnit
      : priceInfo.formattedPricePerUnit,
    frequency: selectedSubscriptionPlan?.frequency,
    interval: selectedSubscriptionPlan?.interval,
  };
};
