import {RootStore} from '../../stores/RootStore';

export const getSlugFromPlatform = (rootStore: RootStore): string => {
  const path = rootStore.controllerParams.controllerConfig.wixCodeApi.location.path;

  if (rootStore.controllerParams.$widget.props.slug) {
    return rootStore.controllerParams.$widget.props.slug;
  }

  if (path.length > 1) {
    return path[path.length - 1];
  }

  return null;
};
