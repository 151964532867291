import {makeAutoObservable} from 'mobx';
import {MAX_QUANTITY_INPUT, MIN_QUANTITY_INPUT, RootWidgetComponentIds} from '../../../config/constants';
import {RootStore} from '../../../stores/RootStore';
import {getQuantityValidation} from '../../validator-service/getQuantityValidation';
import {SPECS} from '../../../../../specs';

export const bindQuantityWidgetProps = (rootStore: RootStore) => {
  const {
    $state,
    controllerParams: {$bind, flowAPI},
  } = rootStore;

  const isQuantityTouchupsEnabled = flowAPI.experiments.enabled(SPECS.QUANTITY_WIDGET_TOUCHUPS);

  const quantityStore = makeAutoObservable({
    get validation() {
      return getQuantityValidation(rootStore);
    },
    get min() {
      return isQuantityTouchupsEnabled
        ? $state.minQuantity ?? MIN_QUANTITY_INPUT
        : $state.isProductSubmitted && $state.inStock
        ? $state.minQuantity
        : MIN_QUANTITY_INPUT;
    },
    get max() {
      return isQuantityTouchupsEnabled
        ? $state.maxQuantity ?? MAX_QUANTITY_INPUT
        : $state.isProductSubmitted && $state.inStock
        ? $state.maxQuantity
        : MAX_QUANTITY_INPUT;
    },
  });
  return $bind(RootWidgetComponentIds.Quantity, {
    max: () => quantityStore.max,
    min: () => quantityStore.min,
    value: () => $state.quantityInput,
    isError: () => quantityStore.validation.isError,
    errorMessage: () => quantityStore.validation.errorMessage,
  });
};
