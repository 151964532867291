import model from './model';
import {
  CtaButtonStates as States,
  CtaButtonsWidgetEvents as Events,
  CtaButtonsWidgetComponentIds as ComponentIds,
  defaultCtaEnglishLabels,
  checkMarkIcon,
} from './config/constants';
import {autorun} from 'mobx';
import {initLocaleKeys} from '../../services/platform-service/initLocaleKeys';
import {SPECS} from '../../specs';

export default model.createController(({$w, $widget, flowAPI, $props, $bindAll}) => {
  const isWidgetViewStatesEnabled = flowAPI.experiments.enabled(SPECS.WIDGET_VIEW_STATES_ON_CTA);
  const t = initLocaleKeys(flowAPI.translations.i18n);
  const getState = () => {
    if (!isWidgetViewStatesEnabled) {
      // TODO:Zeev: this is a temp solution until we have an EditorAPI that controls MSB
      const isEditor = flowAPI.environment.isEditor;
      const activeStateFromSettings = $props.settings?.activeState;
      if (isEditor && activeStateFromSettings && activeStateFromSettings !== 'Default') {
        return activeStateFromSettings;
      }
      // TODO:Zeev: this is a temp solution END
    }
    if ($props.inStock) {
      if ($props.isSubscribeNow) {
        return States.Subscribe;
      } else {
        return States.AddBuy;
      }
    } else if ($props.isPreOrder) {
      return States.PreOrder;
    } else if ($props.enableNotifyMe) {
      return States.NotifyMe;
    } else {
      return States.OutOfStock;
    }
  };
  const isDefaultEnglishLabel = (label) => defaultCtaEnglishLabels.filter((elem) => elem === label).length > 0;

  const getTranslationOrUserText = (label, translation, override?) => {
    // fully tested in sled: https://ecom-e2e.wixsite.com/cta-custom-text/product-page/i-m-a-product

    if (override) {
      return override;
    }

    if (!label || isDefaultEnglishLabel(label)) {
      return translation;
    }

    return label;
  };

  return {
    pageReady: () => {
      $bindAll({
        [ComponentIds.AddToCartBtn]: {
          onClick: () => $widget.fireEvent(Events.AddToCartClick, {}),
          label: () =>
            getTranslationOrUserText(
              $w(ComponentIds.AddToCartBtn).label,
              t.ADD_TO_CART_BUTTON(),
              $props.labelOverrides?.addToCart,
            ),
          disabled: () => $props.isAddToCartInProgress,
        },
        [ComponentIds.PreOrderButton]: {
          onClick: () => $widget.fireEvent(Events.AddToCartClick, {}),
          label: () =>
            getTranslationOrUserText($w(ComponentIds.PreOrderButton).label, t.productPage.preOrderButton.text()),
          disabled: () => $props.isAddToCartInProgress,
        },
        [ComponentIds.BuyNowBtn]: {
          onClick: () => $widget.fireEvent(Events.BuyNowClick, {}),
          label: () =>
            getTranslationOrUserText(
              $w(ComponentIds.BuyNowBtn).label,
              t.BUY_NOW_BUTTON(),
              $props.labelOverrides?.buyNow,
            ),
        },
        [ComponentIds.PreOrderMessage]: {
          deleted: () => !($props.shouldShowPreOrderMessage || getState() === States.PreOrder),
          text: () => $props.preOrderMessage ?? '',
        },
        [ComponentIds.SubscribeNowBtn]: {
          onClick: () => $widget.fireEvent(Events.SubscribeNowClick, {}),
          label: () =>
            getTranslationOrUserText($w(ComponentIds.SubscribeNowBtn).label, t.PRODUCT_PAGE_SUBSCRIBE_NOW_BUTTON()),
        },
        [ComponentIds.NotifyMeBtn]: {
          onClick: () => $widget.fireEvent(Events.NotifyMeClick, {}),
          label: () =>
            getTranslationOrUserText($w(ComponentIds.NotifyMeBtn).label, t.productPage.notifyMeButton.text()),
        },
        [ComponentIds.OutOfStockBtn]: {
          label: () => getTranslationOrUserText($w(ComponentIds.OutOfStockBtn).label, t.PRODUCT_OUT_OF_STOCK_BUTTON()),
          disabled: () => true,
        },
        [ComponentIds.WishlistSlotPlaceholder]: {
          slot: {
            productId: () => $props.productId,
            // @ts-expect-error: no event types on slot
            onWishlistClick: (event) => $widget.fireEvent(Events.WishlistClick, {status: event.data.status}),
          },
        },
      });
      autorun(() => {
        void $w(ComponentIds.MultiStateBox).changeState(getState());
      });
    },
    updateWidgetViewState: (viewStateId) => {
      if (isWidgetViewStatesEnabled) {
        void $w(ComponentIds.MultiStateBox).changeState(viewStateId);
      }
    },
    exports: {
      indicateAddToCartCompleted: async (buttonComponentId) => {
        const label = $w(buttonComponentId).label;
        $w(buttonComponentId).collapseIcon();
        $w(buttonComponentId).label = checkMarkIcon;

        await new Promise((r) => setTimeout(r, 2000));

        $w(buttonComponentId).label = label;
        $w(buttonComponentId).expandIcon();
      },
    },
  };
});
