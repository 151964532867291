import {UserInputType} from '../../../../constants/stores';
import {RootStore} from '../../stores/RootStore';
import {ValidationResult} from '../../../../types/type';
import {SPECS} from '../../../../specs';

export const getQuantityValidation = (rootStore: RootStore): ValidationResult => {
  const isQuantityTouchupsEnabled = rootStore.controllerParams.flowAPI.experiments.enabled(
    SPECS.QUANTITY_WIDGET_TOUCHUPS,
  );

  const {maxQuantity, minQuantity, userInputErrors, isProductSubmitted, quantityInput} = rootStore.$state;
  const isError = isQuantityTouchupsEnabled
    ? userInputErrors[UserInputType.Quantity][0]
    : isProductSubmitted && userInputErrors[UserInputType.Quantity][0];
  return {
    isError,
    errorMessage:
      quantityInput > maxQuantity
        ? rootStore.t.productPage.quantityWidget.errorMessage.exceedsInventory({
            inventory: maxQuantity,
          })
        : rootStore.t.productPage.quantityWidget.errorMessage.minimumQuantity({
            minQuantity,
          }),
  };
};
