import model from './model';
import {QuantityWidgetComponentIds as ComponentIds, QuantityWidgetEvents as Events} from './config/constants';
import {initLocaleKeys} from '../../services/platform-service/initLocaleKeys';
import {clickOnQuantity} from '@wix/bi-logger-ec-sf/v2';
import {SPECS} from '../../specs';

export default model.createController((controllerParams) => {
  const {$w, $widget, $bindAll, $props, flowAPI} = controllerParams;
  const t = initLocaleKeys(flowAPI.translations.i18n);

  const isQuantityTouchupsEnabled = flowAPI.experiments.enabled(SPECS.QUANTITY_WIDGET_TOUCHUPS);

  const reportBI = (value) => {
    void flowAPI.bi.report(
      clickOnQuantity({
        valueInt: value,
        isBlocks: true,
      }),
    );
  };

  const updateQuantity = (quantity) => {
    $w(ComponentIds.Input).value = $props.value as any as string;
    $widget.fireEvent(Events.Change, quantity);
    reportBI(quantity);
  };

  const getQuantity = () => {
    return parseInt($w(ComponentIds.Input).value, 10);
  };

  const increaseQuantity = () => {
    updateQuantity(getQuantity() + 1);
  };

  const decreaseQuantity = () => {
    updateQuantity(getQuantity() - 1);
  };

  return {
    pageReady: () => {
      $w(ComponentIds.Input as any).hideNumberSpinner();
      $bindAll({
        [ComponentIds.Label]: {
          text: () => $props.settings.label ?? t.QUANTITY_LABEL(),
          onClick: () => $w(ComponentIds.Input).focus(),
        },
        [ComponentIds.Input]: {
          max: () => $props.max,
          min: () => $props.min,
          value: () => $props.value as unknown as string,
          onChange: () => updateQuantity(getQuantity()),
          onInput: () => (isQuantityTouchupsEnabled ? updateQuantity(getQuantity()) : undefined),
          accessibility: {
            ariaAttributes: {
              labelledBy: () => $w(ComponentIds.Label),
              describedBy: () => $w(ComponentIds.ErrorMessage).getTextSelector(),
            },
          },
        },
        [ComponentIds.Increase]: {
          onClick: increaseQuantity,
          disabled: () => $props.value >= $props.max,
          accessibility: {
            ariaAttributes: {
              label: () => t.productPage.sr.quantityWidget.plusButton.label(),
            },
          },
        },
        [ComponentIds.Decrease]: {
          onClick: decreaseQuantity,
          disabled: () => $props.value <= $props.min,
          accessibility: {
            ariaAttributes: {
              label: () => t.productPage.sr.quantityWidget.minusButton.label(),
            },
          },
        },
        [ComponentIds.ErrorMessage]: {
          deleted: () => !$props.isError,
          text: () => $props.errorMessage ?? '',
        },
      });
    },
    exports: {
      focus: () => {
        $w(ComponentIds.Input).focus();
      },
    },
  };
});
